@keyframes moveToRight {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }

    100% {
        opacity: 1;
        transform: translateX(-50%);
    }
}

@keyframes moveToLeft {
    0% {
        opacity: 0;
        transform: translateX(0);
    }

    100% {
        opacity: 1;
        transform: translateX(-50%);
    }
}

@keyframes moveToTop {
    0% {
        opacity: 0;
        transform: translateY(60px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}