@mixin responsive($break-point) {
    @if ($break-point ==MobileTablet) {
        @media (max-width: 1023px) {
            @content
        }
    }

    ;

    @if ($break-point ==TabletPC) {
        @media (min-width: 740px) {
            @content
        }
    }

    ;

    @if ($break-point ==Mobile) {
        @media (max-width: 739px) {
            @content
        }
    }

    ;

    @if ($break-point ==Tablet) {
        @media (min-width: 740px) and (max-width: 1023px) {
            @content
        }
    }

    ;

    @if ($break-point ==PC) {
        @media (min-width: 1024px) {
            @content
        }
    }

    ;
}