$light-primary-color: #8e9efc;
$medium-primary-color: #5a73fc;
$dark-primary-color: #1c3eff;
$text-black-color: #666;
$white-color: #fff;
$black-color: #000;
$title-color: #0a192f;
$background-color: #030a15;
$text-color: #64ffda;


$light-secondary-color: #ffb900;
$dark-secondary-color: #ff7730;
$light-tertiary-color: #55c57a;
$dark-tertiary-color: #28b485;