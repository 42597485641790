@import './color.scss';
@import './size_screen.scss';
@import './grid.scss';
@import './keyframes.scss';

.menu {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999;

    &__check-box {
        display: none;
    }

    &__btn {
        display: block;
        top: 24px;
        right: 24px;
        position: absolute;
        width: 60px;
        height: 60px;
        background-color: $background-color;
        border-radius: 50%;
        cursor: pointer;
        border: 2px solid $text-color;
        z-index: 1;

        .menu-btn-icon,
        &::after,
        &::before {
            width: 30px;
            height: 2px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: $text-color;
        }

        .menu-btn-icon {
            opacity: 1;
            transition: opacity .5s linear;
        }

        &::before {
            content: "";
            display: block;
            top: 40%;
            height: 1.2px;
            transition: transform .5s linear;
        }

        &::after {
            content: "";
            display: block;
            top: 60%;
            height: 1.2px;
            transition: transform .5s linear;
        }
    }

    &__nav {
        z-index: 0;
        position: fixed;
        top: 50%;
        left: 200%;
        transform: translate(-50%, -50%);
        transition: left .5s linear;
    }

    &__list {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    &__item {
        text-align: center;
        width: 500px;
    }

    &__link {
        display: block;
        padding: 16px 0;
        text-decoration: none;
        font-size: 2rem;
        text-transform: uppercase;
        color: $white-color;
        font-weight: 300;
        transition: transform .2s linear;

        @include responsive(MobileTablet) {
            -webkit-tap-highlight-color: transparent;
            user-select: none;
        }

        &:hover {
            transform: translateX(4px);
            color: $text-color;
        }
    }


    &__check-box:checked~.menu__bg {
        transform: scale(100);
    }

    &__check-box:checked~.menu__nav {
        left: 50%;
    }

    &__check-box:checked~.menu__btn .menu-btn-icon {
        opacity: 0;
    }

    &__check-box:checked~.menu__btn::before {
        top: 50%;
        transform: translate(-50%, -50%) rotate(135deg);
    }

    &__check-box:checked~.menu__btn::after {
        top: 50%;
        transform: translate(-50%, -50%) rotate(-135deg);
    }

    &__bg {
        position: absolute;
        top: 24px;
        right: 24px;
        width: 60px;
        height: 60px;
        background-color: $background-color;
        border-radius: 50%;
        z-index: -1;
        transition: transform 0.3s linear;
    }
}