@import './color.scss';
@import './size_screen.scss';
@import './grid.scss';
@import './keyframes.scss';
@import './static-ui.scss';
@import './web-app.scss';
@import './menu.scss';
@import './about.scss';
@import './header.scss';
@import './footer.scss';
@import './buttons.scss';
@import './heading.scss';

* {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    font-family: 'Roboto', sans-serif;
    box-sizing: border-box;
    font-size: 62.5%;
    line-height: 1.6rem;
}

:root {
    --wide: 0px;
}

.app-container {
    background-image: linear-gradient(to right bottom, rgba(10, 25, 47, .99), rgba(10, 25, 47, 0.89));
    user-select: none;
}