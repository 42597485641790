@import './color.scss';
@import './size_screen.scss';
@import './grid.scss';
@import './keyframes.scss';

.content-heading {
    display: block;
    width: 80%;
    font-weight: 700;
    font-size: 3rem;
    line-height: 3rem;
    text-align: center;
    color: transparent;
    letter-spacing: .8rem;
    margin: 12px auto;
    -webkit-background-clip: text;
    // đổ backgound-image vào text
    text-transform: uppercase;
    background-image: linear-gradient(to right, rgba($text-color, .99), rgba($text-color, .5));
    transition: transform linear 0.2s;
    cursor: default;
    z-index: 999;

    &:hover {
        transform: skewY(5deg) scale(1.1);
        text-shadow: 5px 8px 10px rgba(0, 0, 0, 0.2);
    }

    @include responsive(Mobile) {
        font-size: 2rem;
        letter-spacing: 0.6rem;
        line-height: 2rem;
    }
}