.footer {
    color: $text-color;
    text-align: center;
    font-size: 2.8rem;
    height: 60px;
    font-style: italic;
    text-shadow: 1px 1px $text-color;

    @include responsive(Tablet) {
        font-size: 2.4rem;
    }

    @include responsive(Mobile) {
        font-size: 2rem;
    }
}